$primaryColor : black;
$primaryColor2 : #666;
$turfGreenAlternative : #333;
@media print {
    .red {
	color: $primaryColor !important;
    }
    .red .emblem {
	color: $primaryColor2 !important;
	border: 1px solid $primaryColor2 !important;
    }
    .red.bold.bullet {
	color: $primaryColor !important;
    }
    .turf-green {
	color : $turfGreenAlternative !important;
    }
    .projector-text {
	color: $primaryColor !important;
    }
    .generic-print-class {
	color: $primaryColor !important;
    }
    body {
	color: $primaryColor !important;
    }
    html {
	color: $primaryColor !important;
    }
}

