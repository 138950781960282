
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//    <link href="{{ mix('css/print-style.css') }}" rel="stylesheet">
@import "./print-style.scss";

.padding-0 {
    padding: 0
}

.emblem.green {
    border: 1px solid #40d613;
    color: #40d613;
}
.mr-2 {
    margin-right: 1em
}
.ml-2 {
    margin-left: 1em
}
